export const getBorderStyle = (status, appointmentIsInThePast) => {
    if (status !== "Booked" && status !== "Reserved") return "solid";
    return appointmentIsInThePast ? "solid" : "dashed";
}

export const getBorderColor = (category, status) => {
    if (category === "Diagnostiekdag" && status === "Reserved") {
        return "var(--color-timeline-reserved-diagnostiekdag)";
    }

    switch (category) {
        case "Medicatieconsult":
            return "var(--color-timeline-medicatieconsult)";
        case "CGT":
            return "var(--color-timeline-cgt)";
        case "Slaaptherapie":
            return "var(--color-timeline-slaaptherapie)";
        case "Psychoeducatie":
            return "var(--color-timeline-psychoeducatie)";
        default:
            return "var(--color-timeline-default)";
    }
}