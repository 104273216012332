import {checkRequiredAccount} from '../Root/Root';
import {InfoList, InfoListItem, InfoListItemText, InfoListWrapper} from '../../components/InfoList/InfoList';
import {redirect, useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {Alert} from '../../components/Alert/Alert';
import {CareTeam} from './Partials/CareTeam';
import {Features, Permissions} from '../../constants/enums';
import {hasFeatureAccess, joinWithCommaAndAmpersand} from '../../utils/helpers';
import React from "react";
import Tooltip from "../../components/Tooltip/Tooltip";
import {ReactComponent as Warning} from '../../assets/icons/warning.svg';
import {ConditionalWrap} from "../../components/Utils/Utils";

const StyledInfoListWrapper = styled(({$hasTimelineFeature = true, ...props}) => (
    <InfoListWrapper $hasTimelineFeature={$hasTimelineFeature} {...props} />
))`
    grid-template-columns: 1fr 1fr 0.75fr 1fr;
`;

const StyledWarning = styled(Warning)`
    width: 24px;
    height: 24px;
    vertical-align: middle;
    flex-shrink: 0;
    margin: 0 4px 0 0;
    
    path {
        fill: var(--color-warning-error);
    }
`;

const StyledTooltip = styled(Tooltip)`
    width: max-content;
    max-width: 240px;
    white-space: unset;
    text-align: left;
    padding: 10px 12px;
`;

export async function summaryLoader({request, params}) {
    try {
        const {account} = await checkRequiredAccount(Permissions.PORTAL_ACCESS_PATIENT_SUMMARY);
        if (!hasFeatureAccess(account, Features.TIMELINE)) return redirect(`/patient/${params.patientUUID}/vragenlijsten`)
        return account;
    } catch (error) {
        const {account} = await checkRequiredAccount();
        if (!hasFeatureAccess(account, Features.TIMELINE)) return redirect(`/patient/${params.patientUUID}/vragenlijsten`)
        return redirect(`/patient/${params.patientUUID}/personalia`)
    }
}

const Summary = () => {
    const {patientData} = useRouteLoaderData("patient");

    const getSetting = () => {
        switch (patientData.setting) {
            case "S02": return "Mono"
            case "S03": return "Multi"
            default: return patientData.setting
        }
    }

    return (
        <>
            <StyledInfoListWrapper $hasTimelineFeature={true}>
                <InfoList $hasTimelineFeature={true}>
                    <InfoListItem label={patientData?.location?.header && patientData.location?.header}>{patientData?.location?.body && patientData.location?.body}</InfoListItem>
                    <InfoListItem label="Status">{patientData.statuses?.[0]?.title}</InfoListItem>
                    <InfoListItem hide={true} label="Comorbiditeit">{patientData.comorbidity}</InfoListItem>
                </InfoList>
                <InfoList $hasTimelineFeature={true}>
                    <InfoListItem label="Primaire diagnose">{patientData.primaryDiagnosis}</InfoListItem>
                    <InfoListItem label="Zorgvraagtype">{patientData.careDemandType}</InfoListItem>
                    <InfoListItem label="Start behandeling">{patientData.carePlanStartDate}</InfoListItem>
                </InfoList>
                <InfoList $hasTimelineFeature={true}>
                    <InfoListItem label="Behandeltraject">{joinWithCommaAndAmpersand(patientData.carePlan)}</InfoListItem>
                    <CareTeam/>
                    {/*<InfoListItem hide={true} label="Hartslag">{patientData.heartRate}</InfoListItem>*/}
                    {/*<InfoListItem hide={true} label="Bloeddruk">{patientData.bloodPressure}</InfoListItem>*/}
                </InfoList>
                <InfoList $hasTimelineFeature={true}>
                    <InfoListItem label="Aantal no-shows" wrapInText={!patientData.noShows}>{patientData.noShows ? <Alert>{patientData.noShows}</Alert> : '0'}</InfoListItem>
                    <InfoListItem label="Setting" wrapInText={false}>
                        {!['S02', 'S03'].includes(patientData.setting) ?
                            <Alert tooltipWidth={'270px'} tooltipText={'Deze setting graag in Medicore aanpassen naar S02 (mono) of S03 (multi).'}>{getSetting()}</Alert>
                            :
                            <InfoListItemText $color={!['S02', 'S03'].includes(patientData.setting) && 'var(--color-warning-error)'}>{getSetting()}</InfoListItemText>
                        }
                    </InfoListItem>
                    {/*<InfoListItem hide={true} label="Medicatie">{patientData.medication}</InfoListItem>*/}
                    {/*<InfoListItem hide={true} label="Medische bijzonderheden">{patientData.medicalDetails}</InfoListItem>*/}
                </InfoList>
            </StyledInfoListWrapper>
        </>
    );
}

export default Summary;