import styled from 'styled-components';
import {FormField} from '../FormFields/FormFields';
import {useEffect, useMemo, useRef} from "react";

const StyledFormFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
`;

export const DefaultFormFields = ({currentStepData, errors, register, getValues, unregister, watch, control, onFormFieldBlur, identifier}) => {
    const watchedValues = watch();
    const selectedOptions = useMemo(() => {
        return Object.values(watchedValues).reduce((result, value) => {
            if (value !== null) {
                if (Array.isArray(value)) {
                    result.push(...value.map(item => item));
                } else if (typeof value === "object") {
                    result.push(value.id);
                }
            }
            return result;
        }, []);
    }, [watchedValues]);

    const shouldRenderField = (item) => {
        return (
            // If an item does not contain triggers
            !(item.triggers && item.triggers.length > 0) ||
            // If any of the triggers are selected
            (item.triggers.some(trigger => selectedOptions.includes(trigger)))
        );
    };

    const unregisteredRef = useRef(new Set());

    useEffect(() => {
        currentStepData?.items.forEach((item) => {
            if (!shouldRenderField(item)) {
                if (!unregisteredRef.current.has(item.id) && getValues(item.id) != null) {
                    unregister(item.id);
                    unregisteredRef.current.add(item.id);
                }
            } else {
                if (unregisteredRef.current.has(item.id)) {
                    unregisteredRef.current.delete(item.id);
                }
            }
        });
    }, [currentStepData, selectedOptions, getValues, unregister]);

    return (
        <StyledFormFields>
            {currentStepData?.items.map((item) =>
                shouldRenderField(item) ? (
                    <FormField
                        key={item.id}
                        type={item.type}
                        id={item.id}
                        label={item.title}
                        required={item.required}
                        description={item.description}
                        disabled={false}
                        error={errors[item.id]}
                        register={register}
                        watch={watch}
                        control={control}
                        onFormFieldBlur={onFormFieldBlur}
                        name={item.id}
                        data={item}
                        identifier={identifier}
                    />
                ) : null
            )};
        </StyledFormFields>
    );
}

export const DefaultFormFieldsAnswers = ({data}) => {
    return (
        <StyledFormFields>
            {data.map(item => (
                <FormField
                    key={item.id}
                    type={item.type}
                    id={item.id}
                    label={item.title}
                    required={item.required}
                    description={item.description}
                    disabled={true}
                    data={item}
                />
            ))}
        </StyledFormFields>
    );
}